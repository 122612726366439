import axios from 'axios'
import { BASE_URL } from '@/service_urls'
import { getCustomerId } from "@/helpers/helper";

const teamMembers = {
    getTeams: (customer_id, organisation_id) => axios.get(`${BASE_URL}/teams/?customer_id=${customer_id}&organisation_id=${organisation_id}`),
    getAllTeamMembers: (customer_id, organisation_id) => axios.get(`${BASE_URL}/teams/get_all_team_members?customer_id=${customer_id}&organisation_id=${organisation_id}`),
    getTeamLevel: () => axios.get(`${BASE_URL}/teams/team_levels`),
    getRecruitmentTypes: () => axios.get(`${BASE_URL}/teams/recruitment_types`),
    updateTeamMembers: ({updated_by, payload}) => axios.put(`${BASE_URL}/teams/update_team_members?updated_by=${updated_by}`,payload),
    createTeamMembers: (
        {customer_id,
        organisation_id,    
        team_id, 
        team_level_id, 
        recruitment_type_id, 
        contact_id}
    ) => axios.post(`${BASE_URL}/teams/customer/${customer_id}/organisation/${organisation_id}/team_members?team_id=${team_id}&team_level_id=${team_level_id}&recruitment_type_id=${recruitment_type_id}&contact_id=${contact_id}`),
    createNewTeamMembers: (
        {customer_id,
        organisation_id,
        team_id, 
        team_level_id, 
        recruitment_type_id, 
        payload}
    ) => axios.post(`${BASE_URL}/teams/customer/${customer_id}/organisation/${organisation_id}/new_team_member?team_id=${team_id}&team_level_id=${team_level_id}&recruitment_type_id=${recruitment_type_id}`, payload)
}

export default teamMembers;
