import axios from 'axios'
import { BASE_URL } from '@/service_urls'

const recruitingAgency = {
    addOrganisations: ({ customer_id, data }) => {
        return axios.post(`${BASE_URL}/recruiting_agency/${customer_id}`, data)
    },
    removeOrganisations: ({ customer_id, data }) => {
        return axios({
            method: 'DELETE',
            url: `${BASE_URL}/recruiting_agency/${customer_id}`,
            data: data
          });
    }
}

export default recruitingAgency