import axios from "axios";
import { BASE_URL } from "@/service_urls";
const filter = {
  licensingAuthority: () => {
    return axios.get(`${BASE_URL}/licensing_authority/`);
  },
  pqrHeaderCountries: () => axios.get(`${BASE_URL}/pqr_header/countries/`),
  pqrHeaderLicensingAuthorities: (query) => axios.get(`${BASE_URL}/pqr_header/issue_authorities/?${query}`),
  // pqrHeaderLicensingAuthorities: (query) => axios.get(`${BASE_URL}/pqr_header/licensing_authorities/?${query}`),
  licenseAuthPqr: payload => {
    return axios.get(`${BASE_URL}/licensing_authority/levels/?${payload}`);
  },
  specialitySearch: (query) => {
    return axios.get(`${BASE_URL}/speciality_search/?${query}`);
  },
  addFavouriteFilter: ({ payload, userId }) => axios.post(`${BASE_URL}/user/${userId}/filter-bookmarks/`, payload),
  getFavouriteFilter: ({ userId, page_name }) => {
    return axios.get(`${BASE_URL}/user/${userId}/filter-bookmarks/by-page-name/?page_name=${page_name}`);
  },
  updateFavouriteFilter: ({ payload, filter_bookmark_id, userId }) => axios.put(`${BASE_URL}/user/${userId}/filter-bookmarks/${filter_bookmark_id}`, payload),
  deleteFavouriteFilter: ({ filter_bookmark_id, userId }) => axios.delete(`${BASE_URL}/user/${userId}/filter-bookmarks/${filter_bookmark_id}`),
  qualificationClassification: ({ queryString }) => axios.get(`${BASE_URL}/qualification/classification/qualification_classifications?${queryString}`),
  pqrHeader: (query) => axios.get(`${BASE_URL}/pqr_header/?${query}`),
  pqrAHPSpeciality: (query) => axios.get(`${BASE_URL}/pqr_ahp_detail/specialities?${query}`),
};

export default filter;
