import axios from "axios";
import { BASE_URL } from "@/service_urls";

const alert = {
  getNotificationAlerts: (alert_to, query) =>
    axios.get(`${BASE_URL}/alert/${alert_to}/notification`),
  clearNotificationAlerts: (payload) =>
    axios.delete(`${BASE_URL}/alert/clear`, { data: payload }),

  // Communication Channels
  getCommunicationChannel: () =>
    axios.get(`${BASE_URL}/communication_channel/`),

  // Communication Channels Details
  getCommunicationChannelDetails: (user_id, customer_id, organisation_id) =>
    axios.get(
      `${BASE_URL}/communication_channel_details/?user_id=${user_id}&customer_uid=${customer_id}&organisation_id=${organisation_id}`
    ),
  createCommunicationChannelDetails: (finalPayload) =>
    axios.post(`${BASE_URL}/communication_channel_details/`, finalPayload),
    updateCommunicationChannelDetails: (detail_id, finalPayload) =>
    axios.put(`${BASE_URL}/communication_channel_details/${detail_id}`, finalPayload),
};

export default alert;
