import axios from 'axios'
import { BASE_URL } from '@/service_urls'


const facility = {
  getOrganisationTypeList: () => axios.get(`${BASE_URL}/organisation/type/?organisation=true`),
  getNextCredentialDetail: (customer_id) =>axios.get(`${BASE_URL}/customer/${customer_id}/nextUser`),
  addFacility: (payload) => axios.post(`${BASE_URL}/customer_registration/`, payload),
  getFacilityList: () => axios.get(`${BASE_URL}/customer/?customer_uid__gte=3`),
  getFacilityLocation: () => axios.get(`${BASE_URL}/location/location/?location_id__notin=4,5,6,7`),
  getCustomerDetails: () => axios.get(`${BASE_URL}/user/customer/`),
  addFacilityDetail: (payload, customer_id) => axios.put(`${BASE_URL}/customer_registration/?customer_id=${customer_id}`, payload),
  getFacilityConfig: (facilityId) => axios.get(`${BASE_URL}/customer_application_configuration/?customer_id=${facilityId}`),
  updateFacilityConfig: (payload, customer_id) => axios.put(`${BASE_URL}/customer_application_configuration/?customer_id=${customer_id}`, payload),
  getCustomerDetailsById: (facilityId) => axios.get(`${BASE_URL}/customer/${facilityId}`),
  downloadCandidatesDocument: customerId => {
    const endUrl = `${BASE_URL}/customer/${customerId}/candidate/export/doh_candidate_status`;
    return axios({
      method: "GET",
      url: endUrl,
      responseType: "blob"
    });
  },
  recoveryPassword: (email) => axios.post(`${BASE_URL}/password-recovery/${email}`),
  addFacilityUser: ({data, customer_id, facility_id}) => axios.post(`${BASE_URL}/customer/${customer_id}/organisation/${facility_id}/user/`, data),
  checkFacilityUser: ({customer_id, payload}) => axios.get(`${BASE_URL}/customer/${customer_id}/config_violated/?config_key=${payload}`),
  userReport: (customer_id) => axios.get(`${BASE_URL}/customer/users/${customer_id}`),
  fetchPublicListOrganisation:  () => axios.get(`${BASE_URL}/public/get-all-organisations/acronym/`),
}

export default facility;