import axios from "axios";
import { BASE_URL } from "@/service_urls";
import { getCustomerId } from "@/helpers/helper";
import router from "@/router/index";
import m from "moment";

const job = {
  jobStatus: () => axios.get(`${BASE_URL}/job/status/`),
  getJobDetail: (jobId) =>
    axios.get(`${BASE_URL}/customer/${getCustomerId()}/job/id/${jobId}/`), // TODO Make Customer Dynamic
  getCandidatesByJob: (job) =>
    axios.get(
      `${BASE_URL}/customer/${getCustomerId()}/job/${job.id
      }/application/candidates?limit=${job.limit}&skip=${job.skip}${job.queryString
      }`
    ), // TODO Make Customer Dynamic
  createJobs: (payload, id) =>
    axios.post(
      `${BASE_URL}/customer/${getCustomerId()}/job/organisation/${id}/`,
      payload
    ),
  updateJobs: (payload, customer_uid, id) =>
    axios.put(
      `${BASE_URL}/customer/${customer_uid}/job/organisation/${id}/`,
      payload
    ),
  getOrganizationDetails: ({ customer_uid, query }) =>
    axios.get(`${BASE_URL}/customer/${customer_uid}/organisation/?${query}`),
  getJobByID: (job_id) =>
    axios.get(`${BASE_URL}/customer/${getCustomerId()}/job/id/${job_id}/`),
  getAllJobs: ({ skip, limit }) =>
    axios.get(
      `${BASE_URL}/customer/${getCustomerId()}/job/?include_marketplace=false?skip=${skip}&limit=${limit}`
    ),
  getBenefits: (organisation_id, payload) =>
    axios.get(
      `${BASE_URL}/organisation/7/organisation/33/job_benefit?candidate_type_id=1&level_id=1`
    ),
  createJobSpeciality: (job_id, customer_uid, payload) =>
    axios.post(
      `${BASE_URL}/customer/${customer_uid}/job/${job_id}/speciality/`,
      payload
    ),
  updateJobSpeciality: (job_id, customer_uid, payload) =>
    axios.put(
      `${BASE_URL}/customer/${customer_uid}/job/${job_id}/speciality/`,
      payload
    ),
  createJobBenefit: (job_id, customer_uid, payload) =>
    axios.post(
      `${BASE_URL}/customer/${customer_uid}/job/${job_id}/benefit/`,
      payload
    ),
  updateJobBenefit: (job_id, customer_uid, job_benefit_id, payload) =>
    axios.put(
      `${BASE_URL}/customer/${customer_uid}/job/${job_id}/benefit/${job_benefit_id}`,
      payload
    ),
  deleteJobBenefit: (job_id, customer_uid, job_benefit_id) =>
    axios.delete(
      `${BASE_URL}/customer/${customer_uid}/job/${job_id}/benefit/${job_benefit_id}`
    ),
  // createJobLevel: (job_id, payload) =>
  //   axios.post(`${BASE_URL}/job/${job_id}/levels/`, payload),
  // updateJobLevel: (job_id, payload) =>
  //   axios.post(`${BASE_URL}/job/${job_id}/levels/`, payload),
  // getJobLevels: (job_id) => axios.get(`${BASE_URL}/job/${job_id}/levels/`),
  createJobNationality: (job_id, customer_id, payload) =>
    axios.post(
      `${BASE_URL}/customer/${customer_id}/job/${job_id}/include_country/`,
      payload
    ),
  updateJobNationality: (job_id, customer_id, payload) =>
    axios.put(
      `${BASE_URL}/customer/${customer_id}/job/${job_id}/include_country/`,
      payload
    ),
  updateOrganisationDesc: (payload) =>
    axios.put(`${BASE_URL}/organisation/`, payload),
  getJobCountries: (job_id, customer_uid) =>
    axios.get(
      `${BASE_URL}/customer/${customer_uid}/job/${job_id}/include_country/`
    ),
  deleteJobCountry: (job_id, customer_uid, job_country_include_id) =>
    axios.delete(
      `${BASE_URL}/customer/${customer_uid}/job/${job_id}/include_country/${job_country_include_id}`
    ),
  deleteJobLevel: (job_id, job_level_id) =>
    axios.delete(`${BASE_URL}/job/${job_id}/levels/${job_level_id}`),
  getOrganisationJobDetails: (organisation_id) =>
    axios.get(
      `${BASE_URL}/organisation/${organisation_id}/job_detail/?all_rows=true`
    ),
  createJobDetails: (job_id, payload) =>
    axios.post(`${BASE_URL}/job/${job_id}/details/`, payload),
  updateJobAdditionalInfo: (job_id, job_detail_id, payload) =>
    axios.put(`${BASE_URL}/job/${job_id}/details/${job_detail_id}`, payload),
  deleteJobDetails: (job_id, job_detail_id) =>
    axios.delete(`${BASE_URL}/job/${job_id}/details/${job_detail_id}`),
  createOrgJobDetail: (organisation_id, payload) =>
    axios.post(
      `${BASE_URL}/organisation/${organisation_id}/job_detail/`,
      payload
    ),
  jobCandidateMatch: ({ job_id, customer_uid }) =>
    axios.post(`${BASE_URL}/jobcandidate/match/${customer_uid}/job/${job_id}`),
  candidateListForJobID: ({ job_id, queryString, statusSubStatus }) =>
    axios.post(
      `${BASE_URL}/jobcandidate/match/job/${job_id}?${queryString}`,
      statusSubStatus
    ),
  statusOptions: ({ tab_id, filter_id, queryString }) =>
    axios.get(
      `${BASE_URL}/jobcandidateprocess/tabs/${tab_id}/filter/${filter_id}/status?${queryString}`
    ),
  subStatusOptions: ({ tab_id, filter_id, action_id, queryString }) =>
    axios.get(
      `${BASE_URL}/jobcandidateprocess/tabs/${tab_id}/filter/${filter_id}/action/${action_id}/sub_status?${queryString}`
    ),
  reasonOptions: ({ tab_id, filter_id, action_id, queryString }) =>
    axios.get(
      `${BASE_URL}/jobcandidateprocess/tabs/${tab_id}/filter/${filter_id}/action/${action_id}/reasons?${queryString}`
    ),
  getUpdateStatusSubStatus: ({ tab_id, filter_id, action_id, queryString }) =>
    axios.get(
      `${BASE_URL}/jobcandidateprocess/tabs/${tab_id}/filter/${filter_id}/get_update_status?${queryString}${action_id ? `&action_id=${action_id}` : ''}`
    ),
  updateJobCandidateStatus: ({ job_id, candidate_uid, payload }) =>
    axios.put(
      `${BASE_URL}/jobcandidate/match/${job_id}/candidate/${candidate_uid}`,
      payload
    ),
  getCandidatePreferredJobs: ({ customer_id, candidate_id, query }) => {
    let subQuery = "";
    if (!router?.currentRoute.fullPath.includes("public/job-board")) {
      subQuery = "&is_own_job__in=null,true";
    }
    if (query) {
      query = query + `&${subQuery}`;
    } else {
      query = `${subQuery}`;
    }
    return axios.get(
      `${BASE_URL}/customer/${customer_id}/job/candidate/${candidate_id}/summary?${query}`
    );
  },
  getCandidateAppliedOrFavouriteJobs: ({ customer_id, candidate_id, query }) =>
    axios.get(
      `${BASE_URL}/customer/${customer_id}/job/candidate/${candidate_id}/jobs/minimal?${query}`
    ),
  fetchJobApplication: (query) => axios.get(`${BASE_URL}/job/application/?${query}`),
  getMatchedJobs: ({ candidate_id, query }) =>
    axios.get(
      `${BASE_URL}/jobcandidate/match/candidate/${candidate_id}?${query}`
    ),
  addJobCandidateComment: ({ job_id, candidate_id, queryString }) =>
    axios.put(
      `${BASE_URL}/jobcandidate/match/${job_id}/candidate/${candidate_id}/comments?${queryString}`
    ),
  getContactMethods: () =>
    axios.get(`${BASE_URL}/jobcandidateprocess/contact_methods`),
  getFieldsInAdvert: () =>
    axios.get(`${BASE_URL}/customer/${getCustomerId()}/job/fields_in_advert`),
  getSectionDisplayNames: ({ customer_uid, organisation_uid }) =>
    axios.get(
      `${BASE_URL}/section_template/display_names/?customer_id=${customer_uid}&organisation_id=${organisation_uid}`
    ),
  fetchSectionByDisplayNames: ({
    customer_uid,
    organisation_uid,
    display_name,
    query,
  }) =>
    axios.get(
      `${BASE_URL}/section_template/${display_name}/display_name?customer_id=${customer_uid}&organisation_id=${organisation_uid}&${query}`
    ),
  createJobDescSections: (customer_uid, job_id, payload) =>
    axios.post(
      `${BASE_URL}/customer/${customer_uid}/job_desc_section/${job_id}`,
      payload
    ),
  deleteJobDescSectionByID: ({ customer_uid, job_desc_section_id }) =>
    axios.delete(
      `${BASE_URL}/customer/${customer_uid}/job_desc_section/job_description/${job_desc_section_id}`
    ),
  benefits: (
    customer_uid,
    organisation_uid,
    candidate_type_id,
    benefit_types
  ) =>
    axios.get(
      `${BASE_URL}/organisation/${customer_uid}/organisation/${organisation_uid}/job_benefit?candidate_type_id=${candidate_type_id}&benefit_types__in=${benefit_types}`
    ),
  getJobComments: (job_id) =>
    axios.get(`${BASE_URL}/job/${job_id}/job_candidate_comment/?all_rows=true`),
  createJobComment: (job_id, payload) =>
    axios.post(`${BASE_URL}/job/${job_id}/job_candidate_comment/`, payload),
  deleteJobComment: (job_id, comment_id) =>
    axios.delete(
      `${BASE_URL}/job/${job_id}/job_candidate_comment/${comment_id}`
    ),
  getActionsForRecruiter: ({ user_id }) =>
    axios.get(
      `${BASE_URL}/action_detail/?initiated_by=${user_id}&assigned_to__isnull=false&skip=0&limit=200&order_by=-action_detail_id`
    ),
  fetchOrganisationRegions: (organisation_id) =>
    axios.get(
      `${BASE_URL}/organisation/organisation_regions/?all_rows=true&organisation_id=${organisation_id}`
    ),
  onChangeJobStatus: ({ customer_uid, job_id, status }) =>
    axios.put(
      `${BASE_URL}/customer/${customer_uid}/job/id/${job_id}?status=${status}`
    ),
  //getInterviewScheduledCandidateActionDetails: ({ queryString }) => axios.get(`${BASE_URL}/action_detail/job/candidate/transactions/?status_id=54&sub_status_id__in=47,55,57&order_by=-action_detail_id&skip=0&limit=200&date_field=initiated_date&from_date=${m().subtract(10, 'days').format("YYYY-MM-DD")}&to_date=${m().add(10, 'days').format("YYYY-MM-DD")}&${queryString}`),
  getInterviewScheduledCandidateActionDetails: ({ queryString }) =>
    axios.get(
      `${BASE_URL}/action_detail/job/candidate/transactions/?status_id=54&sub_status_id__in=47,55,57&order_by=-action_detail_id&completed_by__isnull=true&skip=0&limit=200&${queryString}`
    ),
  getJobPendingActionDetails: ({ queryString }) =>
    axios.get(
      `${BASE_URL}/action_detail/job/candidate/transactions/?status_id=28&sub_status_id__in=72&order_by=-action_detail_id&completed_by__isnull=true&skip=0&limit=200&${queryString}`
    ),
  getJobApprovalAction: ({ queryString }) =>
    axios.get(
      `${BASE_URL}/action_detail/job/transactions/?action_id=19&skip=0&limit=200&order_by=-action_detail_id&date_field=initiated_date&to_date=${m().format(
        "YYYY-MM-DD"
      )}&${queryString}`
    ),
  getJobApprovalTransactionsByJobId: (query) =>
    axios.get(`${BASE_URL}/action_detail/?order_by=-action_detail_id${query}`),
  createPublicJobs: (payload, customer_id, organisation_id) =>
    axios.post(
      `${BASE_URL}/customer/${customer_id}/job/organisation/${organisation_id}/public`,
      payload
    ),
  getPublicJobByID: (customer_id, payload) => {
    // axios.get(`${domain}/customer/${customer_id}/job/id/${job_id}/public`)
    const URL = `${BASE_URL}/customer/${customer_id}/job/call/for/share/jobs`;
    return axios.post(URL, payload);
  },
  fetchEnumLabels: ({ enum_name, query }) =>
    axios.get(`${BASE_URL}/get_enum_labels/${enum_name}?${query}`),
  cloneJob: ({ job_id }) =>
    axios.post(
      `${BASE_URL}/job-board/job/${job_id}/clone?customer_id=${getCustomerId()}`
    ),
  createJobSectionTemplate: ({ customer_id, organisation_id, ...rest }) =>
    axios.post(
      `${BASE_URL}/section_template/create_section_template?customer_id=${customer_id}&organisation_id=${organisation_id}`,
      rest
    ),
  updateJobSectionTemplate: ({
    customer_id,
    organisation_id,
    section_id,
    ...rest
  }) =>
    axios.put(
      `${BASE_URL}/section_template/update_section_template?customer_id=${customer_id}&organisation_id=${organisation_id}&section_id=${section_id}`,
      rest
    ),
  deleteJobSectionTemplate: (section_id) => axios.delete(`${BASE_URL}/section_template/delete_section_template?section_id=${section_id}&&customer_id=${getCustomerId()}`),
  fetchOrgContacts: ({ customer_id, organisation_id }) =>
    axios.get(
      `${BASE_URL}/customer/${customer_id}/organisation/${organisation_id}/contact/?all_rows=true&exclude_admin=true`
    ),
  fetchJobCards: ({ customer_id, query }) =>
    axios.get(
      `${BASE_URL}/customer/${customer_id}/job/cards?status_id=29&skip=0&limit=200&initiated_by=true&order_by=expiry_review_date&date_field=expiry_review_date&from_date=${m().format(
        "YYYY-MM-DD"
      )}${query}`
    ),
  markAsCompleted: ({ action_detail_id, customer_id }) =>
    axios.put(
      `${BASE_URL}/action_detail/mark/${customer_id}/as/reviewed?action_detail_id=${action_detail_id}`
    ),
  jobDocuments: ({ job_id }) => axios.get(`${BASE_URL}/job/${job_id}/document`),
  //getJobDetail: (jobId) => axios.get(`${BASE_URL}/job_id/${jobId}/`),
  offerAcceptedActionDetails: ({ queryString }) =>
    axios.get(
      `${BASE_URL}/action_detail/job/candidate/transactions/?status_id=24&sub_status_id__in=60&order_by=-action_detail_id&skip=0&limit=200&${queryString}`
    ),
  getSubmissionActionDetails: ({ queryString }) =>
    axios.get(
      `${BASE_URL}/action_detail/job/candidate/transactions/?status_id=52&order_by=-action_detail_id&completed_by__isnull=true&skip=0&limit=200&${queryString}`
    ),
  getInterviewRequestAction: ({ queryString }) =>
    axios.get(
      `${BASE_URL}/action_detail/job/candidate/transactions/?status_id__in=54,null&sub_status_id__in=50,51,56,null&order_by=-action_detail_id&completed_by__isnull=true&skip=0&limit=200&${queryString}`
    ),
  //offerActionDetails: ({ queryString }) => axios.get(`${BASE_URL}/action_detail/job/candidate/transactions/?status_id=58&sub_status_id__in=59&order_by=-action_detail_id&completed_by__isnull=true&skip=0&limit=200&date_field=initiated_date&from_date=${m().subtract(10, 'days').format("YYYY-MM-DD")}&to_date=${m().add(10, 'days').format("YYYY-MM-DD")}&${queryString}`),
  onboardActionDetails: ({ queryString }) =>
    axios.get(
      `${BASE_URL}/action_detail/job/candidate/transactions/?status_id=62&sub_status_id__in=69&order_by=-action_detail_id&completed_by__isnull=true&skip=0&limit=200&check_onboard=true&${queryString}`
    ),
  //onboardActionDetails: ({ queryString }) => axios.get(`${BASE_URL}/action_detail/job/candidate/transactions/?status_id=62&sub_status_id__in=69&order_by=-action_detail_id&skip=0&limit=200&date_field=initiated_date&from_date=${m().subtract(10, 'days').format("YYYY-MM-DD")}&to_date=${m().add(10, 'days').format("YYYY-MM-DD")}&${queryString}`),
  offerActionDetails: ({ queryString }) =>
    axios.get(
      `${BASE_URL}/action_detail/job/candidate/transactions/?status_id=58&sub_status_id__in=59,60&order_by=-action_detail_id&completed_by__isnull=true&document_not_submitted=true&skip=0&limit=200&check_offer=true&${queryString}`
    ),
  joiningActionDetails: ({ queryString }) =>
    axios.get(
      `${BASE_URL}/action_detail/job/candidate/transactions/?status_id=62&sub_status_id=63&order_by=-action_detail_id&completed_by__isnull=true&skip=0&limit=200&${queryString}`
    ),
  fetchQualificationClassification: ({ query }) =>
    axios.get(`${BASE_URL}/qualification/classification/?${query}`),
  fetchAllocatedOrganisations: ({ customer_id, job_id }) =>
    axios.get(
      `${BASE_URL}/customer/${customer_id}/job/${job_id}/allocated_orgs`
    ),
  clone_section_templates: (query) =>
    axios.post(
      `${BASE_URL}/section_template/customer/${getCustomerId()}/clone_section_templates?${query}`
    ),

  updateJobsExpiryDate: (action_detail_id, payload) => axios.put(`${BASE_URL}/action_detail/${action_detail_id}`, payload),
  updateJobDetailExpiryDate: ({ customer_uid, payload }) => axios.put(`${BASE_URL}/customer/${customer_uid}/job/expiry`, payload),

  //agency cv transaction
  getAgencyTransaction: (query) => axios.get(`${BASE_URL}/agency/cv/transaction/all?${query}`),
  updateAgencyTransaction: (trans_id, payload) => axios.put(`${BASE_URL}/agency/cv/transaction?transaction_id=${trans_id}`, payload),

  fetchJobApplicationCount: (query) => axios.get(`${BASE_URL}/job/application/total_count?${query}`),
};

export default job;
