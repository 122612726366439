import axios from 'axios'
import { BASE_URL } from '@/service_urls'

const activityList = {
    activityList: ({ user_id, skip, limit }) => axios.get(`${BASE_URL}/action_detail/?assigned_to__contains={${user_id}}&skip=${skip}&limit=${limit}`),
    fetchActivity: ({ queryString }) => axios.get(`${BASE_URL}/action_detail/?${queryString}`),
    interviewReviewer: ({ job_id, candidate_id, queryString }) => axios.put(`${BASE_URL}/jobcandidate/match/${job_id}/candidate/${candidate_id}/reviewer/interview?${queryString}`),
    holdReviewer: ({ job_id, candidate_id, queryString }) => axios.put(`${BASE_URL}/jobcandidate/match/${job_id}/candidate/${candidate_id}/reviewer/hold?${queryString}`),
    disapproveReviewer: ({ job_id, candidate_id, queryString }) => axios.put(`${BASE_URL}/jobcandidate/match/${job_id}/candidate/${candidate_id}/reviewer/disapprove?${queryString}`),
    queryReviewer: ({ job_id, candidate_id, queryString }) => axios.put(`${BASE_URL}/jobcandidate/match/${job_id}/candidate/${candidate_id}/reviewer/query?${queryString}`),
    approveAuthoriser: ({ job_id, customer_id, organisation_uid, action_detail_id, status_id, payload }) => axios.put(`${BASE_URL}/customer/${customer_id}/job/organisation/${organisation_uid}/${job_id}/${action_detail_id}/?status_id=${status_id}`, payload),
    interviewSchedule: ({ job_id, candidate_uid, queryString }) => axios.put(`${BASE_URL}/jobcandidate/match/${job_id}/candidate/${candidate_uid}/interviewer/schedule?${queryString}`),
    interviewFeedBack: ({ job_id, candidate_uid, queryString }) => axios.put(`${BASE_URL}/jobcandidate/match/${job_id}/candidate/${candidate_uid}/interviewer/attend?${queryString}`),
    authoriserActions: ({ customer_id }) => axios.get(`${BASE_URL}/customer/${customer_id}/job/job_authoriser_action`),
    jobTransaction: ({ queryString }) => axios.get(`${BASE_URL}/action_detail/job/transactions/?${queryString}`),
    jobDisplayStatus: ({ queryString }) => axios.get(`${BASE_URL}/customer/9/job/get_display_status?${queryString}`),
    jobCandidateTransaction: ({ queryString }) => axios.get(`${BASE_URL}/action_detail/job/candidate/transactions/?${queryString}`),
    transactionTabCounts: ({ queryString }) => axios.get(`${BASE_URL}/action_detail/transaction/tab/counts/?${queryString}`),
    rejectInterviewCandidate: ({ job_id, candidate_id, queryString }) => axios.put(`${BASE_URL}/jobcandidate/match/${job_id}/candidate/${candidate_id}/interviewer/disapprove?${queryString}`),
    fetchActionDetailByID: (action_detail_id, query) => axios.get(`${BASE_URL}/action_detail/${action_detail_id}?${query}`),
    createInterviewFeedBack: (payload) => axios.post(`${BASE_URL}/interview_feedback/`, payload),
    fetchInterviewFeedBack: (query) => axios.get(`${BASE_URL}/interview_feedback/?${query}`),
    updateInterviewFeedBack: ({ feedback_id, payload }) => axios.put(`${BASE_URL}/interview_feedback/${feedback_id}`, payload),
}
export default activityList;