import axios from 'axios'
import { BASE_URL } from '@/service_urls'

const customerDocumentLibrary = {
    getAllCustomerDocumentTypes: ({ customer_id, queryString }) => axios.get(`${BASE_URL}/customer_document_type/customer/${customer_id}/get_document_type?${queryString}`),
    getDocumentCategory: () => axios.get(`${BASE_URL}/get_enum_labels/customerdoctypecategory`),
    getDocumentExtensions: () => axios.get(`${BASE_URL}/documents/extensions`),
    createCustomerDocType: ({ customer_id, payload }) => axios.post(`${BASE_URL}/customer_document_type/customer/${customer_id}/document_type`, payload),
    updateCustomerDocType: ({ customer_id, payload }) => axios.put(`${BASE_URL}/customer_document_type/customer/${customer_id}/document_type`, payload),
    deleteCustomerDocType: ({ customer_id, customer_document_type_id }) => axios.delete(`${BASE_URL}/customer_document_type/customer/${customer_id}/document_type?customer_document_type_id=${customer_document_type_id}`),
    getCustomerDocumentLib: ({ queryString }) => axios.get(`${BASE_URL}/document_library/get_document_library_all?${queryString}`),
    createCustomerDocumentLibrary: (payload) => axios.post(`${BASE_URL}/document_library/create_document_library`, payload),
    deleteOrganisationDocument: ({ organisation_id, organisation_document_id }) => axios.delete(`${BASE_URL}/organisation/${organisation_id}/document/${organisation_document_id}/`),
    updateCustomerDocumentLibrary: (payload) => axios.put(`${BASE_URL}/document_library/update_document_library`, payload),
    deleteCustomerDocumentLib: (document_library_id) => axios.delete(`${BASE_URL}/document_library/delete_document_library/${document_library_id}`),
    createDocumentAction: ({ customer_id, organisation_id, payload }) => axios.post(`${BASE_URL}/customer/${customer_id}/organisation/${organisation_id}/document/action/`, payload),
    updateDocumentAction: ({ customer_id, organisation_id, document_action_id, payload }) => axios.put(`${BASE_URL}/customer/${customer_id}/organisation/${organisation_id}/document/action/${document_action_id}`, payload),
    getDocumentAction: ({ customer_id,  queryString }) => axios.get(`${BASE_URL}/customer/${customer_id}/document/action/?${queryString}`),
    deleteDocumentAction: ({ customer_id, document_action_id }) => axios.delete(`${BASE_URL}/customer/${customer_id}/document/action/${document_action_id}`),
}
export default customerDocumentLibrary