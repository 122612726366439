import axios from "axios";
import { BASE_URL } from "@/service_urls";
import router from "@/router/index";
import m from "moment";
import { getCustomerId } from "@/helpers/helper";

const publicJobBoard = {
  getPublicJobs: ({ skip, limit }) => {
    let url;
    // const { params: { jobStatus } } = router?.currentRoute;
    // // Active Jobs //Inactive Jobs
    // if (jobStatus == "active") customURL += 'status_id__in=27,28,29&'
    // else if (jobStatus == "inactive") customURL += 'status_id__in=30&'
    // url = `${BASE_URL}/job-board/public/issuer/summary?skip=${skip}&limit=${limit}&order_by=-created_on&date_field=expiry_review_date&from_date=${m().format("YYYY-MM-DD")}`;
    url = `${BASE_URL}/job-board/public/issuer/summary?skip=${skip}&limit=${limit}&order_by=-created_on&customer_id=${getCustomerId()}&date_field=expiry_review_date&from_date=${m().format("YYYY-MM-DD")}`;
    return axios({
      method: "GET",
      url: url,
    });
  },
  softDeletePublicSharedJob: ({ job_id }) => axios.delete(`${BASE_URL}/customer/${getCustomerId()}/job/public/shared/job/${job_id}/soft/delete`),
  updatePublicSharedJob: (job_id, payload) => axios.post(`${BASE_URL}/customer/${getCustomerId()}/job/update/public/issuer/job?job_id=${job_id}`, payload),
};

export default publicJobBoard;
