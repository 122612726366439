import axios from 'axios'
import { BASE_URL } from '@/service_urls'

const dynamicOrgUrl = {
    fetchOrgDetailsByAcronym: (acronym) => axios.get(`${BASE_URL}/customer/organisation/acronym?path_acronym=${acronym}`),
    fetchOrgFilesByAcronym: (acronym) => axios.get(`${BASE_URL}/customer/static/files/?acronym=${acronym}`),
    isEmailBelongsToAcronym: (email, acronym) => axios.get(`${BASE_URL}/customer/organisation/user_acronym?user_email=${email}&path_acronym=${acronym}`),
    fetchAcronymByOrgDetail: ({ customer_uid, organisation_uid, location_id }) => axios.get(`${BASE_URL}/customer${customer_uid}/organisation/${organisation_uid}/acronym?location_id=${location_id}`),
    postOrgFilesByAcronym: (acronym, file) => axios.post(`${BASE_URL}/customer/static/files/?acronym=${acronym}`, file),
    deleteOrgFilesByAcronym: (query) => axios.put(`${BASE_URL}/customer/static/files/delete?${query}`),
    getOrganisationID: (organisation_id) => axios.get(`${BASE_URL}/organisation/id/${organisation_id}`),
    fetchAcronymByCustomerID: (customer_id, query) => axios.get(`${BASE_URL}/customer/${customer_id}/acronym?${query}`),
}

export default dynamicOrgUrl;