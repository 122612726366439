import axios from 'axios'
import { BASE_URL } from '@/service_urls'

const jobGroup = {
  createJobGroup: (payload) => {
    return axios.post(`${BASE_URL}/job_groups/`, payload)
  },
  fetchJobGroups: (query) => {
    return axios.get(`${BASE_URL}/job_groups/?${query}`)
  },
  updateJobGroup: ({ group_id, ...payload }) => {
    return axios.put(`${BASE_URL}/job_groups/${group_id}`, payload)
  },
  deleteJobGroup: (group_id) => {
    return axios.delete(`${BASE_URL}/job_groups/${group_id}`)
  },
}

export default jobGroup;