import axios from 'axios'
import { BASE_URL } from '@/service_urls'

const externalUsers = {
    addExternalUsers: ({ customer_uid, payload }) => axios.post(`${BASE_URL}/external_user/customer/${customer_uid}/`, payload),
    getContactExternalUsers: ({ query }) => axios.get(`${BASE_URL}/external_user/contact/external/users?${query}`),
    getExternalUserByHashId: ({ customer_uid, hash_id }) => axios.get(`${BASE_URL}/external_user/${hash_id}`),
    createIEFByExternal: (payload) => axios.post(`${BASE_URL}/interview_feedback/external_user`, payload),
    updateIEFByExternal: ({ feedback_id, payload }) => axios.put(`${BASE_URL}/interview_feedback/${feedback_id}/external_user`, payload),
    updateExternalUserByHash: ({ hash_id, payload }) => axios.put(`${BASE_URL}/external_user/${hash_id}`, payload),
    fetchActionDetailByHash: (action_hash_id) => axios.get(`${BASE_URL}/action_detail/interview/action/${action_hash_id}`),
}

export default externalUsers