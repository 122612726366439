import axios from 'axios'
import { BASE_URL } from '@/service_urls'
import m from "moment";

const tabFilters = {
    getJobCandidateTabs: () => axios.get(`${BASE_URL}/jobcandidateprocess/tabs`),
    jobCandidateCount: ({ tab_id, queryString }) => axios.get(`${BASE_URL}/jobcandidate/match/tab/${tab_id}/count?${queryString}`),
    getTabFilters: ({ tab_id }) => axios.get(`${BASE_URL}/jobcandidateprocess/tabs/${tab_id}/filters?user_action=display`),
    getTabSubFilters: ({ tab_id, filter_id }) => axios.get(`${BASE_URL}/jobcandidateprocess/tabs/${tab_id}/filter/${filter_id}/sub_filters?user_action=display`),
    getDisplayStatusSubStatus: ({ queryString }) => axios.get(`${BASE_URL}/jobcandidateprocess/tabs/filter/get_display_status/?${queryString}`),
    tabFilterCandidateCount: ({ queryString }) =>
        axios.get(`${BASE_URL}/jobcandidate/match/tab/filter/count/?${queryString}`),
    favouriteCount: ({ payload, queryString }) => axios.post(`${BASE_URL}/jobcandidate/match/count?${queryString}`, payload),
    jobCandidateTabTotalCount: (queryString) => axios.get(`${BASE_URL}/jobcandidate/match/total_count?${queryString}`),
    fetchJobCandidatesByStatusID: ({ skip, limit, queryString, formPayload }) =>
        axios.post(`${BASE_URL}/jobcandidate/match/candidates?skip=${skip}&limit=${limit}&${queryString}`, formPayload),
    getDisplayStatusSubStatusByTab: ({ tab_id }) => axios.get(`${BASE_URL}/jobcandidateprocess/tabs/${tab_id}/get_display_status`),
    // getInterviewSchedule: ({ queryString }) => axios.get(`${BASE_URL}/action_detail/?${queryString}`),
    getInterviewSchedule: ({ queryString }) => axios.get(`${BASE_URL}/action_detail/job/candidate/transactions/?${queryString}`),
    fetchJobCandidateForAdvanced: ({ queryString, payload }) => axios.post(`${BASE_URL}/jobcandidate/match/recruitment-status?${queryString}`, payload),
}
export default tabFilters;