import axios from 'axios'
import { BASE_URL } from '@/service_urls'

const speciality = {
  speciality: () => axios.get(`${BASE_URL}/speciality/?order_by=speciality`),
  subSpeciality: (queryString) => axios.get(`${BASE_URL}/sub_speciality/?order_by=sub_speciality${queryString?'&'+queryString:''}`),
  skill: () => axios.get(`${BASE_URL}/skill/`),
  specialInterest: () => axios.get(`${BASE_URL}/special_interest/?order_by=special_interest`),
  specialInterestByParams: (query) => axios.get(`${BASE_URL}/special_interest/by/params?${query}`),
  studentCourseSpeciality: (query) => axios.get(`${BASE_URL}/speciality/?${query}`)
}

export default speciality