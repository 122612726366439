import axios from "axios";
import { BASE_URL } from "@/service_urls";

const orgConfiguration = {
  // email ststic assests
  getEmailStaticAssetsEnum: () =>
    axios.get(`${BASE_URL}/get_enum_labels/email_static_assets`),
  getEmailStaticAssets: (acronym) =>
    axios.get(
      `${BASE_URL}/customer/static/files/images/for/email?acronym=${acronym}`
    ),
  uploadEmailStaticAssets: (acronym, formData) =>
    axios.post(
      `${BASE_URL}/customer/static/files/images/for/email?acronym=${acronym}`,
      formData
    ),
  deleteEmailStaticAssets: (acronym, query) =>
    axios.put(
      `${BASE_URL}/customer/static/files/images/for/email/delete?acronym=${acronym}&${query}`
    ),

  // email ststic assests
  getEmailStaticTemplateEnum: () =>
    axios.get(`${BASE_URL}/get_enum_labels/email_static_template`),
  getEmailStaticTemplateContent: (url) =>
    axios.get(url, {
      transformRequest: (data, headers) => {
        delete headers["Authorization"];
        return data;
      },
    }),
  getStaticEmailTemplate: (acronym) =>
    axios.get(
      `${BASE_URL}/customer/static/files/email/templates/?acronym=${acronym}`
    ),
  uploadEmailStaticTemplate: (acronym, formData) =>
    axios.post(
      `${BASE_URL}/customer/static/files/email/templates/?acronym=${acronym}`,
      formData
    ),
  deleteEmailStaticTemplate: (acronym, query, type) =>
    axios.put(
      `${BASE_URL}/customer/static/files/email/templates/delete?acronym=${acronym}&template_type=${type}&${query}`
    ),

  // org ststic assests
  getOrgStaticAssetsEnum: () =>
    axios.get(`${BASE_URL}/get_enum_labels/org_static_assets`),
  getExistingConfigThemeFiles: (acronym) =>
    axios.get(
      `${BASE_URL}/customer/static/files/?acronym=${acronym}&existing=true`
    ),

  // email template content
  getEmailTemplateContent: (acronym, template_type, query) =>
    axios.get(
      `${BASE_URL}/customer/static/files/email/templates/content?acronym=${acronym}&template_type=${template_type}${query}`
    ),
  postEmailTemplateContent: (acronym, items, template_type) =>
    axios.post(
      `${BASE_URL}/customer/static/files/email/templates/content?acronym=${acronym}&template_type=${template_type}`,
      items
    ),

  // Emal config
  postEmailConfiguration: (payload) =>
    axios.post(`${BASE_URL}/configuration/email`, payload),
  getEmailConfiguration: (acronym) =>
    axios.get(`${BASE_URL}/configuration/email/settings?acronym=${acronym}`),
  updateEmailConfiguration: (config_id, payload) =>
    axios.put(`${BASE_URL}/configuration/email?email_config_id=${config_id}`, payload),
};

export default orgConfiguration;
