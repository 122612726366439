import axios from 'axios'
import { BASE_URL } from '@/service_urls'

const contactUser = {
    fetchContact: ({ customer_uid, organisation_uid }) => axios.get(`${BASE_URL}/customer/${customer_uid}/organisation/${organisation_uid}/contact/`),
    fetchUserType: () => axios.get(`${BASE_URL}/customer_user_type/`),
    fetchContactType: () => axios.get(`${BASE_URL}/contact/type/`),
    createUserExisting: ({ customer_id, organisation_id, queryString }) => axios.post(`${BASE_URL}/customer/${customer_id}/organisation/${organisation_id}/contact_user/existing_contact?${queryString}`),
    createUserNew: ({ customer_id, organisation_id, payload }) => axios.post(`${BASE_URL}/customer/${customer_id}/user/recruiter/contact_user?organisation_id=${organisation_id}`, payload),
    fetchContactUser: ({ customer_uid, organisation_uid }) => axios.get(`${BASE_URL}/customer/${customer_uid}/organisation/${organisation_uid}/contact_user/`),
    updateCustomerUser: ({ customer_id, organisation_id, payload }) => axios.put(`${BASE_URL}/customer/${customer_id}/organisation/${organisation_id}/contact_user/update_contact`, payload),
    fetchCustomerContactUser: ({ customer_uid,  query }) => axios.get(`${BASE_URL}/customer/${customer_uid}/user/recruiter/contact_user?${query}`),
    updateCustomerContactUser: ({ customer_id, organisation_id, payload }) => axios.put(`${BASE_URL}/customer/${customer_id}/user/recruiter/contact_user?organisation_id=${organisation_id}`, payload),
}
export default contactUser;