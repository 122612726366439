import axios from "axios";
import { BASE_URL, SUB_BASE_URL } from "@/service_urls";

const auditHistory = {
  fetchAuditStatusChange: ({ query }) =>
    axios.get(
      `${BASE_URL}/action_detail/status_change?order_by=-created_on&${query}`
    ),
  fetchAuditComments: ({ query }) =>
    axios.get(
      `${BASE_URL}/action_detail/comments?order_by=-created_on&action_id__in=18,19&${query}`
    ),
  fetchAuditCommunications: ({ query }) =>
    axios.get(
      `${BASE_URL}/action_detail/communications?order_by=-created_on&${query}`
    ),
  fetchAuditCandidateTransactions: ({ customer_uid, candidate_uid, query }) =>
    axios.get(
      `${BASE_URL}/customer/${customer_uid}/candidate/${candidate_uid}/audit_trails/?order_by=-action_date_time&date_field=action_date_time&${query}`
    ),
  fetchAuditCandidateEmailCommunication: ({ candidate_uid, query }) =>
    axios.get(
      `${SUB_BASE_URL}/communication/email/conversation?candidate_uid=${candidate_uid}&order_by=-message_id&date_field=load_datetime&${query}`
    ),
  fetchAuditPrivateJobComments: ({ job_id, query }) =>
    axios.get(`${BASE_URL}/job/${job_id}/job_candidate_comment/?order_by=-created_on&${query}`),
  fetchAuditPrivateCandidateComments: ({ candidate_uid, query }) =>
    axios.get(
      `${BASE_URL}/candidate/${candidate_uid}/job_candidate_comment/?order_by=-created_on&${query}`
    ),
  fetchAuditJobCandidateCommentsView: ({ query }) =>
    axios.get(
      `${BASE_URL}/action/job_candidate_comments_view/?order_by=-created_on&${query}`
    ),
  fetchAuditFilteredCandidateAuditView: ({ query }) =>
    axios.get(
      `${BASE_URL}/action/filtered_candidate_audit_view/?order_by=-created_on&${query}`
    ),
  fetchAuditJobApplications: ({ query }) =>
  axios.get(
    `${BASE_URL}/action_detail/job_applications?order_by=-created_on&${query}`
  ),
};

export default auditHistory;
