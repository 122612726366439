import axios from 'axios'
import { BASE_URL } from '@/service_urls'

const organisationDocuments = {
  getDocument: (organisation_id, query) => axios.get(`${BASE_URL}/organisation/${organisation_id}/document?${query}`),
  createDocument: (organisation_id, payload) => axios.post(`${BASE_URL}/organisation/${organisation_id}/document`, payload),
  getOrgDocumentType: () => axios.get(`${BASE_URL}/documents/types?order_by=document_type&entity_id=2`),
  downloadDocumentById: (organisation_id, organisation_document_id) => axios.get(`${BASE_URL}/organisation/${organisation_id}/document/${organisation_document_id}/file`),
  deleteDocument: ({organisation_id, organisation_document_id}) => axios.delete(`${BASE_URL}/organisation/${organisation_id}/document/${organisation_document_id}/`),
  getOrgLocation: (location_id) => axios.get(`${BASE_URL}/location/location/id/${location_id}`),
  getDocumentLibraryByOrgId:(organisation_id) => axios.get(`${BASE_}/document_library/get_document_library_all?organisation_id=${organisation_id})`),
  createDocumentLibrary:(payload) => axios.post(`${BASE_URL}/document_library/create_document_library`,payload),
  updateOrganisationDocument: (organisation_id, organisation_document_id, payload) => axios.put(`${BASE_URL}/organisation/${organisation_id}/document/${organisation_document_id}`, payload),
  makeOrgDocumentDefault: (organisation_id, organisation_document_id) => axios.put(`${BASE_URL}/organisation/${organisation_id}/document/${organisation_document_id}/make_default`),
  updateDocumentLibrary: (document_library_id, payload) => axios.put(`${BASE_URL}/document_library/${document_library_id}/update_document_library`, payload),
  deleteDocumentFile: (organisation_id, organisation_document_id) => axios.delete(`${BASE_URL}/organisation/${organisation_id}/document/${organisation_document_id}/doc_file`),
}

export default organisationDocuments