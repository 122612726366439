import axios from "axios";
import { BASE_URL } from "@/service_urls";

const campaign = {
    fetchCampaignById: (campaign_id) =>
        axios.get(`${BASE_URL}/campaign/${campaign_id}`),
    fetchCampaigns: (query) =>
        axios.get(`${BASE_URL}/campaign/?${query}&order_by=-created_on`),
    registerCampaign: (queryString, payload) => axios.post(`${BASE_URL}/campaign/?${queryString}`, payload),
    fetchCampaignFields: (campaign_id) =>
        axios.get(`${BASE_URL}/campaign_fields/?campaign_id=${campaign_id}&all_rows=true`),
    createCampaignTransactions: (payload) =>
        axios.post(`${BASE_URL}/campaign_transactions/`, payload),

    createCampaignField: (campaign_id, payload) => axios.post(`${BASE_URL}/campaign_fields/?campaign_id=${campaign_id}`, payload),
    updateCampaignField: (campaign_id, payload) => axios.put(`${BASE_URL}/campaign_fields/?campaign_id=${campaign_id}`, payload),
    deleteCampaignField: (campaign_field_id) => axios.delete(`${BASE_URL}/campaign_fields/${campaign_field_id}`),
    getCampaignField: ({ campaign_id }) => axios.get(`${BASE_URL}/campaign_fields/?campaign_id=${campaign_id}&skip=0&limit=100&all_rows=false&fetch_row_count=false`),
    createCampaignTransactions: (url, payload) => axios.post(url, payload),
    updateCampaignData: (campaign_uuid, queryString, payload = null) => {
        if (payload) {
            return axios.put(`${BASE_URL}/campaign/${campaign_uuid}?${queryString}`, payload)
        }
        return axios.put(`${BASE_URL}/campaign/${campaign_uuid}?${queryString}`)
    },
    downloadCampaignData: (campaign_id) => axios.get(`${BASE_URL}/campaign_transactions/export/excel?campaign_id=${campaign_id}`, { responseType: 'blob' }),
    getCampaignsCount: (query) => axios.get(`${BASE_URL}/campaign/?${query}`),
    getCampaignsByEmail: (query) => axios.get(`${BASE_URL}/campaign_transactions/check/already_registered?${query}`),
    getCampaignsDataByCampaignUserID: (query) => axios.get(`${BASE_URL}/campaign_transactions/?${query}`),
    checkCandidateExistInCampaign: ({queryString}) => axios.get(`${BASE_URL}/campaign/candidate/check/?${queryString}`),
    updateCampaignPreRegCompleted: ({queryString}) => axios.put(`${BASE_URL}/campaign_pre_reg_candidates/update_completed_status/?${queryString}`),
};

export default campaign;
