import axios from 'axios'
import { BASE_URL,TICKET_URL } from '@/service_urls'

const dashboard = {
    getCandidateByType: () => axios.get(`${BASE_URL}/candidate/stats/by_candidate_type`),
    getCandidateByTypeStats: (customerId, query) => axios.get(`${BASE_URL}/candidate/stats/by_candidate_type_stats?customer_id=${customerId}&${query}`),
    htmlToPDF: (payload) => {
        const url = `${BASE_URL}/html_pdf_convert/`;
        return axios({
            method: "POST",
            url,
            responseType: "blob",
            data: payload,
        });
    },
    fetchLastTransactions: (queryString) => axios.get(`${BASE_URL}/action/recent_actions_view/?${queryString}`),
    getAllInterviewFeedback: (queryString) => axios.get(`${BASE_URL}/interview_feedback/all_interviews/?${queryString}`),
    createReportIssue: (payload) => axios.post(`${TICKET_URL}/issue/report_issue`, payload),
}

export default dashboard;
