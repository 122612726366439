import axios from 'axios'
import { BASE_URL } from '@/service_urls'

const preScreening = {
    fetchQuestionTypes: (query) => axios.get(`${BASE_URL}/question_types/?${query}`),
    createJobQuestion: ({ customer_uid, payload, job_id }) => axios.post(`${BASE_URL}/customer/${customer_uid}/job_questions/${job_id}`, payload),
    updateJobQuestion: ({ customer_uid, job_id, payload }) => axios.put(`${BASE_URL}/customer/${customer_uid}/job_questions/${job_id}`, payload),
    deleteJobQuestion: ({ customer_uid, question_id }) => axios.delete(`${BASE_URL}/customer/${customer_uid}/job_questions/${question_id}`),
    getPreScreeningQuestions: ({ customer_uid, query }) => axios.get(`${BASE_URL}/customer/${customer_uid}/job_questions/?${query}`),
    createCandidateTransaction: ({ customer_uid, job_id, candidate_uid, payload }) => axios.post(`${BASE_URL}/customer/${customer_uid}/transactions/job/${job_id}/candidate/${candidate_uid}`, payload),
    deleteCandidateTransaction: ({ customer_uid, job_id, candidate_uid }) => axios.delete(`${BASE_URL}/customer/${customer_uid}/transactions/job/${job_id}?candidate_uid=${candidate_uid}`),
    fetchJobPreScreeningResponses: ({ customer_uid, job_id }) => axios.get(`${BASE_URL}/customer/${customer_uid}/transactions/job/${job_id}/candidates/`),
    fetchCandidateTransaction: ({ customer_uid, queryString }) => axios.get(`${BASE_URL}/customer/${customer_uid}/transactions/?${queryString}`),
}

export default preScreening