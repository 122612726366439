import axios from 'axios'
import { BASE_URL } from '@/service_urls'

const languageScore = {
  getLanguageExam: () => axios.get(`${BASE_URL}/test/`),
  getLanguageExamScores: (test_id) => axios.get(`${BASE_URL}/test_scores/${test_id}`),
  createCandidateTestScore: (payload) => axios.post(`${BASE_URL}/candidate_tests/`, payload),
  getCandidateTestScores: (candidate_uid)  => axios.get(`${BASE_URL}/candidate_tests/uid/${candidate_uid}`),
  getTestSection:() => axios.get(`${BASE_URL}/test_sections/`),
  updateTestSection:(test_id, section_data, candidate_uid) => axios.put(`${BASE_URL}/candidate_tests/section_update/${candidate_uid}/${test_id}`, section_data),
  deleteCandidateTest:(candidate_test_id) => axios.delete(`${BASE_URL}/candidate_tests/${candidate_test_id}`),
  getTestScores:() => axios.get(`${BASE_URL}/test_scores/`),
  getTestExamTypes:() => axios.get(`${BASE_URL}/test_exam_type/`),
}

export default languageScore