import axios from 'axios'
import { BASE_URL } from '@/service_urls'
import { getCustomerId } from "@/helpers/helper";

const jobAllocation = {
  getJobsForAllocation: () => axios.get(`${BASE_URL}/customer/${getCustomerId()}/job/job_id/job_title?status_id=29`),
  getCustomerOrganizations: ({ customer_id, query }) => axios.get(`${BASE_URL}/customer/${customer_id}/organisation/?${query}`),
  getAllocatedJobs: (customer_id, query) => axios.get(`${BASE_URL}/customer/${customer_id}/job/allocated?${query}`),
  getAllocatedJobsCount: (customer_id) => axios.get(`${BASE_URL}/customer/${customer_id}/job/allocated/jobs/total_count`),
  allocateJob: (customer_id, payload) => axios.post(`${BASE_URL}/customer/${customer_id}/job/allocate`, payload),
  reallocateJob: (customer_id, payload) => axios.put(`${BASE_URL}/customer/${customer_id}/job/reallocate`, payload),
  unallocateJob: (customer_id, allocation_id) => axios.delete(`${BASE_URL}/customer/${customer_id}/job/unallocate/${allocation_id}`)
}

export default jobAllocation