import axios from "axios";
import { BASE_URL } from "@/service_urls";

const managementReport = {
    getJobCandidateProcessCount: (query) => axios.get(`${BASE_URL}/action/job_candidate_process_count_view/?${query}`),
    downloadManagementReport: (query) => {
        const endUrl = `${BASE_URL}/action/job_candidate_process_count_view/export/excel?${query}`;
        return axios({
            method: "GET",
            url: endUrl,
            responseType: "blob"
        });
    },
    fetchUserConfiguration: (query) => axios.get(`${BASE_URL}/configuration/get_all/?${query}`),
    createUserConfiguration: (payload) => axios.post(`${BASE_URL}/configuration/create`, payload),
    updateUserConfiguration: (configuration_id, payload) => axios.put(`${BASE_URL}/configuration/${configuration_id}`, payload),
}

export default managementReport;