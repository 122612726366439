import axios from "axios";
import { BASE_URL, SUB_BASE_URL } from "@/service_urls";

const communication = {
  // Email
  getEmailConversation: (emailID, sender, order) =>
    axios.get(
      `${SUB_BASE_URL}/communication/email/conversation?email_id=${emailID}&sender=${sender}&order_by=${order}`
    ),
  sendOutboundEmail: (formData) =>
    axios.post(`${SUB_BASE_URL}/communication/email/outbound`, formData),
  downloadAttachment: (attachment_id) =>
    axios.get(
      `${SUB_BASE_URL}/communication/email/file?attachment_id=${attachment_id}`
    ),
  getCandidateEmailMessages: (
    email,
    skip,
    limit,
    direction,
    order,
    customer_uid,
    query
  ) =>
    axios.get(
      `${SUB_BASE_URL}/communication/email/conversation?direction=${direction}&email_id=${email}&skip=${skip}&limit=${limit}&order_by=${order}&customer_uid=${customer_uid}&${query}`
    ),
  deleteEmailConversation: (deleteId) =>
    axios.delete(
      `${SUB_BASE_URL}/communication/email/delete?message_id=${deleteId}`
    ),
  updateReadMessage: (message_id, read) =>
    axios.put(
      `${SUB_BASE_URL}/communication/email/${message_id}/?read=${read}`
    ),
  getEmailMessagesAndCount: (query) =>
    axios.get(`${SUB_BASE_URL}/communication/email/messages?${query}`),
  getSearchTermEmailMessagesCount: (query, searchTerm) =>
    axios.get(
      `${SUB_BASE_URL}/communication/email/search?${query}&searchTerm=${searchTerm}`
    ),
  getRecruiterEmailMessages: (sender, query, skip, limit) =>
    axios.get(
      `${SUB_BASE_URL}/communication/email/conversation?sender=${sender}&${query}&skip=${skip}&limit=${limit}&order_by=-message_id`
    ),
  getSearchTermEmailMessages: (query, skip, limit, searchTerm) =>
    axios.get(
      `${SUB_BASE_URL}/communication/email/conversation?${query}&skip=${skip}&limit=${limit}&order_by=-message_id&searchTerm=${searchTerm}`
    ),
  getAllUserEmailSearchTerm: (customer_id, org_id, skip, limit, searchTerms) =>
    axios.get(
      `${SUB_BASE_URL}/communication/email/${customer_id}/candidate?order_by=-created_on${org_id}&skip=${skip}&limit=${limit}${searchTerms}`
    ),
  getAllCustomerContactUserEmail: (
    customer_id,
    org_id,
    current_role,
    role,
    query
  ) => {
    let url;
    if (current_role == role.systemAdmin)
      url = `${SUB_BASE_URL}/communication/email/user${query}`;
    else if (org_id && customer_id)
      url = `${SUB_BASE_URL}/communication/email/${customer_id}/user${query}`;
    else if (customer_id)
      url = `${SUB_BASE_URL}/communication/email/${customer_id}/user${query}`;

    return axios({
      method: "GET",
      url: url,
    });
  },
  getEmailCc: (formData) =>
    axios.post(`${SUB_BASE_URL}/communication/email/alternate_email`, formData),

  // WhatsApp and Message
  getMessageConversation: (sender, receiver, media) =>
    axios.get(
      `${SUB_BASE_URL}/communication/message/conversation?sender=${sender}&receiver=${receiver}&media=${media}`
    ),
  sendOutboundWhatsapp: (sender, whatsapp_to, message) =>
    axios.post(
      `${SUB_BASE_URL}/communication/whatsapp/outbound?sender_name=${sender}&whatsapp_to=${whatsapp_to}&message_content=${message}`
    ),
  sendOutboundMessage: (sender, message_to, message) =>
    axios.post(
      `${SUB_BASE_URL}/communication/message/outbound?sender_name=${sender}&message_to=${message_to}&message_content=${message}`
    ),

  // Voice
  getVoiceCallDevice: ({ identity }) =>
    axios.post(
      `${SUB_BASE_URL}/communication/voice/call/token?identity=${identity}`
    ),

  fetchDocumentFile: (url) => {
    const endUrl = `${BASE_URL}/${url}`;
    return axios({
      method: "GET",
      url: endUrl,
      responseType: "blob",
    });
  },
};

export default communication;
