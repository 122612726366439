import axios from 'axios'
import { BASE_URL } from '@/service_urls'

const login = {
  loginAccessToken: (payload, query) => axios.post(`${BASE_URL}/login/access-token${query}`, payload),
  forgotPassword: (email, path_acronym) => axios.post(`${BASE_URL}/password-recovery/${email}?path_acronym=${path_acronym}`),
  resetPassword: (payload) => axios.post(`${BASE_URL}/reset-password/`, payload),
  unsubscribe: (email) => axios.get(`${BASE_URL}/candidate/unsubscribe/?email=${email}`),
  refreshToken: (refresh_token) => axios.post(`${BASE_URL}/login/refresh-token/?refresh_token=${refresh_token}`),
  changePassword: ({ username, new_password }) => axios.put(`${BASE_URL}/change-password/${username}/new_password/${new_password}`),
  checkOrgRelationship: (payload) => axios.post(`${BASE_URL}/customer/relationship_validation/`, payload),
  checkEmailOTP: (payload) => axios.post(`${BASE_URL}/customer/verify/email`, payload),
  getAcl: ({ query }) => axios.get(`${BASE_URL}/customer_application_configuration/acl?${query}`),
}

export default login